<template>
    <!-- 产品类别 -->
    <div class="materia-type">
        <div class="materia-top">
                <el-button size="small" type="primary" @click="addClick">新增类别</el-button>
        </div>
        <div class="materia-content">
            <div class="content-box" v-for="(item,index) in materiaList" :key="index">
                <div class="box-materia">
                    <div class="materia-name" v-bind:title="item.target">{{item.target}}</div>
                    <div class="materia-line"></div>
                    <div class="pic-box">
                        <img src="./img/edit.png" class="picture picbtn" @click="deitData(item)" />
                        <img src="./img/delete.png" class="picture" @click="deleteData(item)"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer" v-if="listQuery.total>=10">
            <el-pagination
                @current-change="handleCurrentChange"
                :current-page="listQuery.current"
                :page-size="listQuery.size"
                layout="total, prev, pager, next, jumper"
                :total="listQuery.total"
                ref="pagination"
            >
            </el-pagination>
        </div>
        <!-- 新增弹框 -->
        <el-dialog
            :title="titleName+'类别'"
            :visible.sync="dialogVisible"
            width="480px"
            :showClose="false"
            :close-on-click-modal="false"
            top="10vh"
            >
            <el-form :model="addData" size="small" label-width="100px" ref="addData">
                <el-form-item label="产品类别"
                prop="target"
                :rules="{required: true, message: '请输入产品类别', trigger: 'blur'}"
                >
                    <el-input v-model.trim="addData.target" style="width:250px"></el-input>
                </el-form-item>
                <el-form-item label="背景图："
                prop="image"
                :rules="{required: true, message: '请上传背景图', trigger: 'blur'}"
                >
                    <div class="upload-image">
                        <el-upload
                        class="avatar-uploader1"
                        :action="projectName + '/common/backstage/file/fileupload'"
                        :headers="headers"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess"
                        :before-upload="beforeAvatarUpload"
                        >
                        <img v-if="addData.image" :src="projectName + '/common/backstage/file/fileDownLoad?filePath=' + encodeURIComponent(addData.image)" class="avatar" />
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </div>
                </el-form-item>
                <div class="list-box">
                    <el-form-item label="二级分类："
                    prop="radio"
                    :rules="{required: true, message: '请选择',}"
                    >
                        <el-radio v-model="addData.radio" label="有" @change="radioChange">有</el-radio>
                        <el-radio v-model="addData.radio" label="无" @change="radioChange">无</el-radio>
                    </el-form-item>
                    
                </div>
                <div class="list-box1">
                <div v-for="(item, index) in addData.lanhuProductCategoryAddRequstList" :key="index" class="list-box">
                    <div class="img-delete" v-if="(addData.lanhuProductCategoryAddRequstList.length>=2 && index>=1) && addData.radio=='有'" @click="deletebtnClick(index)">
                        <img src="./img/deletebtn.png" style="width:30px;height:30px">
                    </div>
                    <div class="img-add" v-if="addData.radio=='有' && index == 0" @click="addbtnClick">
                        <img src="./img/add.png" style="width:30px;height:30px">
                    </div>
                    <el-form-item
                    v-if="addData.radio=='有'"
                    :prop="'lanhuProductCategoryAddRequstList.' + index + '.target'"
                    :rules="[{required: true,message: '请输入二级分类',}]"
                    >
                    <el-input v-model.trim="item.target" style="width:210px" placeholder="请输入"></el-input>
                </el-form-item>
                </div>
                </div>
                <div class="list-box">
                    <el-form-item label="空间配置："
                    prop="radio1"
                    :rules="{required: true, message: '请选择',}"
                    >
                        <el-radio v-model="addData.radio1" label="有" @change="radioChange1">有</el-radio>
                        <el-radio v-model="addData.radio1" label="无" @change="radioChange1">无</el-radio>
                    </el-form-item>
                   
                </div>
                <div class="list-box1">
                <div v-for="(item, index) in addData.lanhuPlaces" :key="index" class="list-box">
                    <div class="img-delete" v-if="(addData.lanhuPlaces.length>=2 && index>=1) && addData.radio1=='有'" @click="deletebtnClick1(index)">
                        <img src="./img/deletebtn.png" style="width:30px;height:30px">
                    </div>
                    <div class="img-add" v-if="addData.radio1=='有' && index == 0" @click="addbtnClick1">
                        <img src="./img/add.png" style="width:30px;height:30px">
                    </div>
                    <el-form-item
                    v-if="addData.radio1=='有'"
                    :prop="'lanhuPlaces.' + index + '.placeName'"
                    :rules="[{required: true,message: '请输入空间配置',}]"
                    >
                    <el-input v-model.trim="item.placeName" style="width:210px" placeholder="请输入"></el-input>
                </el-form-item>
                </div>
            </div>
                <div class="list-box">
                    <el-form-item label="主流品牌："
                    prop="radio2"
                    :rules="{required: true, message: '请选择',}"
                    >
                        <el-radio v-model="addData.radio2" label="有" @change="radioChange2">有</el-radio>
                        <el-radio v-model="addData.radio2" label="无" @change="radioChange2">无</el-radio>
                    </el-form-item>
                   
                </div>
                <div class="list-box1">
                <div v-for="(item, index) in addData.brandNameList" :key="index" class="list-box">
                    <div class="img-delete" v-if="(addData.brandNameList.length>=2 && index>=1) && addData.radio2=='有'" @click="deletebtnClick2(index)">
                        <img src="./img/deletebtn.png" style="width:30px;height:30px">
                    </div>
                    <div class="img-add" v-if="addData.radio2=='有' && index == 0" @click="addbtnClick2">
                        <img src="./img/add.png" style="width:30px;height:30px">
                    </div>
                    <el-form-item
                    v-if="addData.radio2=='有'"
                    :prop="'brandNameList.' + index + '.brandName'"
                    :rules="[{required: true,message: '请输入主流品牌',}]"
                    >
                    <el-input v-model.trim="item.brandName" style="width:210px" placeholder="请输入"></el-input>
                </el-form-item>
                </div>
                
            </div>
                
            </el-form>
            <div class="footer-btn">
                <el-button size="small" @click="cancelClick">取 消</el-button>
                <el-button size="small" type="primary" @click="addSure" v-if="titleName=='新增'">确 定</el-button>
                <el-button size="small" type="primary" @click="editSure" v-else>确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { categorylist,categoryadd,categoryedit,categorydetails,remove } from "../../api/index";
import {projectName} from "../../util/config"
    export default {
      data() {
        return {
            dialogVisible:false,
            titleName:"",
            projectName:projectName,
            headers: {sessionId: sessionStorage.getItem("access_token"),},
            addData:{
                target:"",//产品名称
                image:"",
                radio:'有',
                radio1:'有',
                radio2:'有',
                lanhuProductCategoryAddRequstList:[{target:''}],
                lanhuPlaces:[{placeName:''}],
                brandNameList:[{brandName:''}],

            },
            listQuery:{
                current: 1,
                size: 10,
                total: 0,
                type:0,//产品类别
            },
            materiaList:[],
        }
      },
      mounted() {
        if (this.$route.query.current) {
      this.listQuery.current = this.$route.query.current - 0;
      this.$nextTick(() => {
        this.$refs.pagination.internalCurrentPage = this.listQuery.current;
      });
    }
        this.getTableDataList()
      },
      methods:{
        getTableDataList() {
            let data = {
                ...this.listQuery
            }
            categorylist(data).then(res=>{
            if(res.code==1) {
                this.materiaList = res.data.records || [];
                this.listQuery.total = res.data.total || 0
                }
            })
        },
        handleCurrentChange(current) {
            this.listQuery.current = current;
            this.getTableDataList()
        },
        addClick() {
            this.titleName = '新增'
            // this.dialogVisible = true
            this.$router.push({ name: "typeAdd" });
        },
        handleAvatarSuccess(res, file) {
            this.addData.image = res.data.nginxFileReadPath
        },
        beforeAvatarUpload(file) {},

        radioChange(val){
            if(val == '无') {
                this.addData.lanhuProductCategoryAddRequstList = [{target:''}];
            } else if(val=='有' && this.addData.lanhuProductCategoryAddRequstList.length==0){
                this.addData.lanhuProductCategoryAddRequstList.push(
                    {target:''}
                )
            }
        },  
        radioChange1(val){
            if(val == '无') {
                this.addData.lanhuPlaces = [{placeName:''}];
            } else if(val=='有' && this.addData.lanhuPlaces.length==0){
                this.addData.lanhuPlaces.push(
                    {placeName:''}
                )
            }
        }, 
        radioChange2(val){
            if(val == '无') {
                this.addData.brandNameList = [{placeName:''}];
            } else if(val=='有' && this.addData.brandNameList.length==0){
                this.addData.brandNameList.push(
                    {brandName:''}
                )
            }
        },  
        addSure() {
            if(this.addData.radio == '无') {
                this.addData.lanhuProductCategoryAddRequstList = [];
            }
            if(this.addData.radio1 == '无') {
                this.addData.lanhuPlaces = [];
            }
            if(this.addData.radio2 == '无') {
                this.addData.brandNameList = [];
            }
            let data = {
                ...this.addData,
                type:0,
            }
            this.$refs.addData.validate((valid)=>{
                if(valid) {
                    categoryadd(data).then(res=>{
                        if(res.code==1) {
                            this.$message.success("新增成功！");
                            this.getTableDataList()
                            this.dialogVisible = false;
                            this.$refs.addData.resetFields();
                            this.addData= {
                                target:"",//产品名称
                                image:"",
                                radio:'有',
                                radio1:'有',
                                radio2:'有',
                                lanhuProductCategoryAddRequstList:[{target:''}],
                                lanhuPlaces:[{placeName:''}],
                                brandNameList: [{brandName:''}],
                            }
                        }
                    })
                }
            })
        },
        addbtnClick() {
            this.addData.lanhuProductCategoryAddRequstList.push(
                {target:''}
            )
        },
        addbtnClick1() {
            this.addData.lanhuPlaces.push(
                {placeName:''}
            )
        },
        addbtnClick2() {
            this.addData.brandNameList.push(
                {brandName:''}
            )
        },
        deletebtnClick(index) {
            let dataArr = this.addData.lanhuProductCategoryAddRequstList;
            if(dataArr.length>=2) {
                this.addData.lanhuProductCategoryAddRequstList.splice(index,1)
            } else {
            
            }
        },
        deletebtnClick1(index) {
            let dataArr = this.addData.lanhuPlaces;
            if(dataArr.length>=2) {
                this.addData.lanhuPlaces.splice(index,1)
            } else {
            
            }
        },
        deletebtnClick2(index) {
            let dataArr = this.addData.brandNameList;
            if(dataArr.length>=2) {
                this.addData.brandNameList.splice(index,1)
            } else {
            
            }
        },
        // 删除
        deleteData(val) {
            console.log(val.id,"ffffff")
            this.$confirm('确定删除此产品类别?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
                remove({id:val.id}).then(res=>{
                    if(res.code==1) {
                        this.$message.success("删除成功！");
                        this.getTableDataList()
                    }
                })
            }).catch(() => {});
        },
        // 取消
        cancelClick() {
            this.dialogVisible = false;
            this.$refs.addData.resetFields();
            this.addData = {
                target:"",//产品名称
                image:"",
                radio:'有',
                radio1:'有',
                radio2:'有',
                lanhuProductCategoryAddRequstList:[{target:''}],
                lanhuPlaces:[{placeName:''}],
                brandNameList: [{brandName:''}],

            }
        },
        // 编辑 
        deitData(val) {
            this.titleName = '编辑'
            this.$router.push({ name: "typeAddOrEdit",query:{id:val.id,current:this.listQuery.current}});
            // categorydetails({id:val.id}).then(res=>{
            //     if(res.code==1) {
            //         this.dialogVisible = true;
            //         let data = res.data || {};
            //         this.addData = {...data} || {}
            //         if(data.lanhuProductCategoryAddRequstList.length>0) {
            //             this.$set(this.addData,"radio","有")
            //         } else {
            //            this.$set(this.addData,"radio","无") 
            //         //    this.addData.lanhuProductCategoryAddRequstList.push({target:''})
            //         }
            //         if(data.lanhuPlaces.length>0) {
            //             this.$set(this.addData,"radio1","有")
            //         } else {
            //            this.$set(this.addData,"radio1","无") 
            //         //    this.addData.lanhuProductCategoryAddRequstList.push({target:''})
            //         }
            //         if(data.brandNameList.length>0) {
            //             this.$set(this.addData,"radio2","有")
            //         } else {
            //            this.$set(this.addData,"radio2","无") 
            //         //    this.addData.lanhuProductCategoryAddRequstList.push({target:''})
            //         }
            //         console.log(this.addData,"this.addData")
            //     }
            // })

        },
        editSure() {
            if(this.addData.radio == '无') {
                this.addData.lanhuProductCategoryAddRequstList = [];
            }
            if(this.addData.radio1 == '无') {
                this.addData.lanhuPlaces = [];
            }
            if(this.addData.radio2 == '无') {
                this.addData.brandNameList = [];
            }
            let data = {
                ...this.addData,
                id:this.addData.id,
                type:0,
            }
            console.log(data,"data")
            this.$refs.addData.validate((valid)=>{
                if(valid) {
                    categoryedit(data).then(res=>{
                        if(res.code==1) {
                            this.$message.success("编辑成功！");
                            this.getTableDataList()
                            this.dialogVisible = false;
                            this.$refs.addData.resetFields();
                            this.addData= {
                                target:"",//产品名称
                                image:"",
                                radio:'有',
                                radio1:'有',
                                lanhuProductCategoryAddRequstList:[{target:''}],
                                lanhuPlaces:[{placeName:''}],
                                brandNameList: [{brandName:''}],
                            }
                        }
                    })
                }
            })
        },
      }  
    }
</script>
    
<style lang="less" scoped>
.footer-btn{
    display: flex;
    justify-content: flex-end;
}
.materia-top{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
}
.materia-type {
    margin-left:20px;
    .right-btn {
        display: flex;
        .import-car {
            background: #E7FBF4;
            color: #15D393;
            border: 1px solid #E7FBF4;
            margin-right:20px
        }
    }
    /deep/.el-upload {
        border: 0;
    }
    .el-form-box {
        display: flex;
        justify-content: space-between;
        align-items: center;

    }
    .materia-content {
        display: flex;
        flex-wrap: wrap;
        .content-box {
            .box-materia {
                width: 220px;
                height: 90px;
                background: #FFFFFF;
                border: 1px solid #E5E5E5;
                border-radius: 5px;
                margin:0 30px 30px 0;
                display: flex;
                align-items: center;
                .materia-name {
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #666666;
                    margin:0 20px;
                    width: 120px;
                    text-align: center;
                    overflow: hidden;
                    text-overflow:ellipsis;
                    white-space: nowrap;
                }
                .materia-line {
                    width: 1px;
                    height: 50px;
                    background: #CCCCCC;
                }
                .pic-box {
                    margin:0 25px;
                    .picture {
                        width: 17px;
                        height: 17px;
                        display: block;
                        cursor: pointer;
                    }
                    .picbtn {
                        padding-bottom: 15px;
                    }
                }
            }
        }
    }

}
.footer {
    display: flex;
    justify-content: flex-end;
}
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 208px;
    height: 108px;
    line-height: 108px;
    text-align: center;
    border: 1px dashed;
  }
  .avatar {
    width: 208px;
    height: 108px;
    display: block;
  }
  .list-box1{
    // position: relative;
    overflow-y: auto;
    max-height: 100px;
  }
  .list-box {
    position: relative;
    
  }
  .img-add {
        position: absolute;
        right: 70px;
        top: 0px;
        cursor: pointer;
        z-index: 9;
    }
    .img-delete {
        position: absolute;
        right: 70px;
        top: 0px;
        cursor: pointer;
        z-index: 9;
    }
      
</style>